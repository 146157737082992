import { Drawer } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import useWalletConnection from "../../hooks/useConnection";
import Header from "../../screens/header";
import { formatAdd } from "../../utils/Utility";
import Modal from "../Modal";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

export default function MobileNavigation({
  children,
  drawerItem,
  open,
  setOpen,
}) {
  const location = useLocation();
  const { publicAdd } = useSelector((state: any) => state.bridge);
  const { connectWalletHandler } = useWalletConnection();

  return (
    <div className="absolute min-h-screen">
      {open &&<SwipeableDrawer
          anchor={'right'}
          open={open}
          onOpen={() => {
          }}
          onClose={() => setOpen(false)}
      >     <div className="modal-nav">{drawerItem}</div></SwipeableDrawer>}
      {/*<Modal modalIsOpen={open} close={() => setOpen(false)}>*/}

      {/*</Modal>*/}
      <div className="bg-light min-h-[60px]">
        <div className="flex justify-between px-4 py-2 h-full items-center">
          <span className="text-[10px] text-center text-primary">
            <img
              className={"w-16 p-1 bg-dark rounded-[4px]"}
              src={"/svgs/logo.svg"}
            />
            BRIDGE
          </span>
          <div className="flex gap-4 items-center">
            <div className="flex gap-2 text-[13px]" onClick={connectWalletHandler}>
              <img src={"/metaMask.png"} className="w-5 h-5" alt="metamask icon"/>
              {formatAdd(publicAdd)}
            </div>
            <div className="cursor-pointer"  onClick={() => setOpen((s) => !s)} >
              <img className={"logo-image"} src={"/svgs/menu.svg"} />
            </div>
          </div>
        </div>
      </div>
      <div className="w-screen bg-white px-4 pb-8 min-h-screen">{children}</div>
      {location.pathname === "/" && !!!publicAdd && (
        <div className="fixed bottom-0">
          <Header fixed={true} />
        </div>
      )}
    </div>
  );
}
