import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import App from "./App";
import { store } from "./app/store";
import ThemeProvider from "./theme/theme-provider";

import "./index.scss";
import CommonContextProvider from "./context/common";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ThemeProvider>
    <Provider store={store}>
      <CommonContextProvider>
        <App />
      </CommonContextProvider>
    </Provider>
  </ThemeProvider>
);
