export const ROUTES = {
  default: "/",
  manual: "/manual",
  history: "/history",
  status: "/status",
  transaction: "/trx/:id",
};

export const NAV_ROUTES = [
  {
    route: ROUTES.default,
    label: "Token Bridge",
    icon: "/svgs/tokenbridge1.svg",
    iconDark: "/svgs/tokenbridge.svg",
  },
  {
    route: ROUTES.manual,
    label: "Manual",
    icon: "/svgs/manual.svg",
    iconDark: "/svgs/manual1.svg",
  },
  {
    route: ROUTES.history,
    label: "Transaction",
    icon: "/svgs/Transaction.svg",
    iconDark: "/svgs/Transaction1.svg",
  },
  {
    route: ROUTES.status,
    label: "Status",
    icon: "/svgs/statuspage.svg",
    iconDark: "/svgs/statuspage1.svg",
  },
];
