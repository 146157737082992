import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomSpinner from "../../components/spinner";
import TableComponent from "../../components/Table";
import { setTransactions } from "../../features/bridgeSlice";
import { Headers_Labels, TitleStyle } from "../../utils/common";
import { errorToast } from "../../utils/Toasts";
import { getTransactionsList } from "../../utils/Utility";
import "./styles.scss";
import WalletConnect from "../../components/wallet-connect";

export default function TransactionHistory() {
  const { publicAdd } = useSelector((state: any) => state.bridge);
  const [{ page, totalPage }, setPage] = useState({
    page: 1,
    totalPage: 1,
  });
  const [loading, setloading] = useState(false);
  const page_size = 10;
  const { transactions } = useSelector((state: any) => state.bridge);
  const dispatch = useDispatch();

  const getTransactionList = async () => {
    if (!!!publicAdd) return;
    try {
      setloading(true);
      let res = await getTransactionsList({
        wallet_address: publicAdd,
        page,
        page_size,
      });
      if (res.data.code !== 0) {
        errorToast("Failed to fetch transaction list");
        return;
      }
      dispatch(setTransactions(res?.data?.data?.transactions || []));
      setPage((s) => ({ ...s, totalPage: res?.data?.data?.meta.totalPage }));
      setloading(false);
    } catch (error) {
      setloading(false);
      errorToast(error.message);
    }
  };

  useEffect(() => {
    getTransactionList();
  }, [page, publicAdd]);

  if (!!!publicAdd) {
    return <WalletConnect title={'Transaction History'}/>
  }

  return (
    <div className="w-full pt-10">
      <span className={TitleStyle}>Transaction History</span>
      <div className="text-base mt-4">
        Keep track of your Plastic Hero Bridge Transaction History.
      </div>
      <div className="history-list-wrapper mt-5">
        {loading ? (
          <CustomSpinner />
        ) : (
          <TableComponent
            data={transactions || []}
            headers={Headers_Labels}
            pagination={true}
            onNext={(page) => setPage((s) => ({ ...s, page }))}
            page={page}
            totalPage={totalPage}
          />
        )}
      </div>
    </div>
  );
}
