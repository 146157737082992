import { useMediaQuery } from "@mui/material";
import React, { createContext, useEffect, useState } from "react";

type authProps = {
  children: React.ReactNode;
};

const initialState = {
  isMobile: false,
};

export const CommonContext = createContext({
  ...initialState,
});

const CommonContextProvider = ({ children }: authProps) => {
  const [isMobile, setIsMobile] = useState(initialState.isMobile);
  const matches = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    setIsMobile(matches);
  }, [matches]);

  return (
    <CommonContext.Provider value={{ isMobile: isMobile }}>
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContextProvider;
