import React from "react";
import "./Button.css";
import BeatLoader from "react-spinners/BeatLoader";

const Button = (props: any) => {
  return (
    <button
      disabled={props.disabled ? props.disabled : false}
      style={{
        color: props.color ? props.color : "#ffff",
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : "#4CB648",
        width: props.width ? props.width : "100%",
        margin: props.margin ? props.margin : 0,
        fontWeight: props.fontWeight ? props.fontWeight : 400,
        fontSize: props.fontSize ? props.fontSize : 14,
        border: props.border ? props.border : "none",
      }}
      className="Button"
      onClick={() => {
        props.onClick();
      }}
    >
      {props.loader ? (
        <BeatLoader
          color="#000000"
          loading={props.loader}
          size={8}
        ></BeatLoader>
      ) : (
        props.label
      )}
    </button>
  );
};

export default Button;
