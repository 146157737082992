import React, {useImperativeHandle, useRef} from "react";
import "./TextInput.css";

const TextInput = React.forwardRef((props: any, ref) => {
    const inputRef = useRef(null);


    useImperativeHandle(ref, () => {
        return {
            clear() {
                inputRef.current.value = ''
            },
        };
    }, []);

    return (
        <div className="relative w-full">
            {!!props.label && <span className="label-input">{props.label}</span>}
            <div
                style={{
                    maxWidth: props.maxWidth ? props.maxWidth : "100%",
                    width: props.width ? props.width : "100%",
                    marginRight: props.marginRight ? props.marginRight : null,
                    padding: props.padding ? props.padding : 0,
                    marginLeft: props.marginLeft ? props.marginLeft : null,
                    backgroundColor: props.backgroundColor
                        ? props.backgroundColor
                        : "#ffff",
                    borderBottom: props.borderBottom ? props.borderBottom : "none",
                }}
                className="inputHolder"
            >
                {props.img ? <img src={props.img}></img> : null}
                {props.icon ? props.icon : null}
                <input
                    id={props.id}
                    inputMode={props.type === 'number' ? 'decimal' : 'text'}
                    pattern={props.type === 'number' ? "[0-9]*" : ''}
                    onKeyDown={(e) => {
                        if (props.onKeyDown) {
                            props.onKeyDown(e);
                        }
                    }}
                    /* onFocus={()=>{props.onFocus()}} onBlur={()=>{props.onBlur()}} */ disabled={
                    props.disabled
                }
                    ref={inputRef}
                    placeholder={props.placeholder}
                    style={{
                        textAlign: props.align,
                        backgroundColor: props.backgroundColor
                            ? props.backgroundColor
                            : "#ffff",
                        fontWeight: props.fontWeight ? props.fontWeight : "400",
                        fontSize: props.fontSize ? props.fontSize : 24,
                        letterSpacing: props.letterSpacing ? props.letterSpacing : "normal",
                        color: props.color ? props.color : "#fffff",
                        zIndex: props.zIndex || 99999,
                    }}
                    value={props.value}
                    className="input"
                    type={props.type}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                />
            </div>
        </div>
    );
});

export default TextInput;
