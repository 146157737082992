export const termsArray = {
  title: "Terms of Use for Plastic Hero Bridge",
  header:
    'Thank you for choosing Plastic Hero Bridge, the cryptocurrency swap service provided by EcoCentre. These terms of use ("Terms") apply to your use of the Plastic Hero Bridge platform and the services provided by EcoCentre. By accessing or using the Plastic Hero Bridge platform, you agree to be bound by these Terms.',
  content: [
    {
      title: "Overview",
      descr:
        "Plastic Hero Bridge is a Swap Service that allows users to swap Plastic Hero Coins (PTH) for Plastic Hero Token (PTH). EcoCentre is the company providing this service.",
    },
    {
      title: "Eligibility",
      descr:
        "By using Plastic Hero Bridge, you represent and warrant that you are at least 18 years of age and have the legal capacity to enter into these Terms.",
    },
    {
      title: "User Conduct",
      descr:
        "You are solely responsible for your conduct while using Plastic Hero Bridge. You agree to use the service only for lawful purposes and in compliance with all applicable laws and regulations.",
    },
    {
      title: "Network Fees",
      descr:
        'When using Plastic Hero Bridge, you may be required to pay Network Fees (also known as "Transaction Fees", "Gas Fees", or "Network Fees") for the computational resources needed to execute a swap request on the Blockchain. Please note that the amount of Network Fees are not under the control of EcoCentre, and you are solely responsible for having sufficient cryptocurrency stored in your wallet address to complete any transaction on the Blockchain before initiating a swap request. We will use reasonable efforts to inform you of any Network Fees before initiating our services.',
    },
    {
      title: "Transactions",
      descr:
        "Plastic Hero Bridge processes transactions between Plastic Hero Coins (PTH) and Plastic Hero Token (PTH). The value of these coins may fluctuate, and EcoCentre is not responsible for any losses incurred due to changes in value.",
    },
    {
      title: "Disclaimers",
      descr:
        'Plastic Hero Bridge is provided "as is" and without warranty of any kind, either express or implied, including, without limitation, warranties of merchantability, fitness for a particular purpose, or non-infringement.',
    },
    {
      title: "Limitation of Liability",
      descr:
        "EcoCentre shall not be liable to you for any damages, including without limitation direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with your use of Plastic Hero Bridge.",
    },
    {
      title: "Indemnification",
      descr:
        "You agree to indemnify and hold EcoCentre harmless from any and all claims, damages, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or in connection with your use of Plastic Hero Bridge.",
    },
    {
      title: "Governing Law",
      descr:
        "These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where EcoCentre is located.",
    },
    {
      title: "Termination",
      descr:
        "The Terms of Use for Plastic Hero Bridge by EcoCentre will remain in effect until terminated by either party. You may terminate this agreement by discontinuing the use of our swap services at any time for any reason. We reserve the right to cease providing any or all of our services at any time, including if we suspect you have violated these Terms, created a risk or legal issue for us, or our provision of services is no longer commercially viable. In all cases, any provision in these Terms that are intended to remain in effect after termination shall continue to apply.",
    },
    {
      title: "Changes to the Terms",
      descr:
        "EcoCentre reserves the right to change these Terms at any time without prior notice. Your continued use of Plastic Hero Bridge after the changes have been made constitutes your acceptance of the new Terms.",
    },
  ],
  footer:
    "If you have any questions or concerns about these Terms or Plastic Hero Bridge, please contact our support team.",
};
