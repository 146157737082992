import {Button} from "@mui/material";
import React from "react";
import {TitleStyle} from "../../utils/common";

export default function Manual() {

    const openEngManual = () => {
        window.open('https://abbcswapmanuals.s3.eu-central-1.amazonaws.com/manual-en.pdf', '_blank')
    }
    const openKoManual = () => {
        window.open('https://abbcswapmanuals.s3.eu-central-1.amazonaws.com/manual-kr.pdf', '_blank')
    }

    return (
        <div className="w-full max-w-full md:max-w-[650px] pt-10 center-text-manual">
            <span className={TitleStyle}>User Manual</span>
            <div className="text-base mt-4 max-w-[600px] description-manual">
                Read this user manual to learn about the Plastic Hero Bridge and its
                content. This guide will help you set up your account and swap your PTH
                coins.
            </div>
            <div className="flex flex-col md:flex-row gap-4 mt-20 w-full items-center">
                <Button
                    className={'manual-btn'}
                    onClick={openEngManual}
                    sx={{
                        width: 200,
                        borderColor: "#4CB648",
                        borderRadius: 2,
                        padding: 1,
                        color: "#000000",
                    }}
                    variant="outlined"
                >
                    English
                </Button>
                <Button
                    className={'manual-btn'}
                    onClick={openKoManual}
                    sx={{
                        width: 200,
                        borderColor: "#4CB648",
                        borderRadius: 2,
                        padding: 1,
                        color: "#000000",
                    }}
                    variant="outlined"
                >
                    한국어 KO
                </Button>
            </div>
        </div>
    );
}
