import React from "react";
import { Dialog } from "@mui/material";

const Modal = ({ modalIsOpen, close, className="", children }) => {
  return (
    <Dialog open={modalIsOpen} onClose={close} className={className}>
      {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {close ? (
          <AiOutlineClose
            style={{ cursor: "pointer" }}
            onClick={() => {
              close();
            }}
            size={20}
            color="#f7991c"
          ></AiOutlineClose>
        ) : null}
      </div> */}
      {children}
    </Dialog>
  );
};

export default Modal;
