import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";

import "./table.scss";

export default function TableComponent({
  data = [],
  headers = [],
  pagination = true,
  onNext = (pg) => {},
  page = 1,
  totalPage = 1,
}) {
  return (
    <div className="table-container">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  sx={{
                    width: header.width,
                    textAlign: header?.align || "left",
                  }}
                >
                  <span className={`text-[18px] font-semibold`}>
                    {header.label}
                  </span>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(data) &&
              data.length > 0 &&
              data?.map((row) => (
                <TableRow key={row.value}>
                  {headers.map((header, index) => (
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        cusor:
                          !!header?.redirectUrl && row[header.redirectUrl]
                            ? "pointer"
                            : "none",
                        textAlign: header?.align || "left",
                      }}
                      onClick={() =>
                        !!header?.redirectUrl &&
                        row[header.redirectUrl] &&
                        row[header?.value] &&
                        window.open(row[header.redirectUrl], "_blank")
                      }
                    >
                      {header.value === "transferIcon" ? (
                        <img
                          src={"/svgs/towardsArrow.svg"}
                          alt="convert to arrow"
                        />
                      ) : (
                        <span
                          className={`text-[15px] text-[#091A00] flex gap-1 justify-${
                            header?.align || "start"
                          }`}
                        >
                          {!!header.logoUrl && !!row[header.logoUrl] && (
                            <img
                              src={row[header.logoUrl]}
                              alt="logo url"
                              className="w-5 h-5"
                            />
                          )}
                          {row[header.value] || (
                            <span className="text-[14px] font-normal text-secondary">
                              No data
                            </span>
                          )}
                        </span>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {data.length == 0 ? (
        <div className="flex justify-center items-center w-full text-[16px] font-semibold min-h-[80px]">
          No data found
        </div>
      ) : (
        <div className="flex justify-end mt-3 py-3">
          <div className="w-full md:w-80 flex text-right">
            <div
              className={`pagination-wrapper ${
                page == 1 && totalPage == 1
                  ? "equal-page"
                  : page === totalPage
                  ? "last-page"
                  : page === 1
                  ? "first-page"
                  : ""
              }`}
            >
              <KeyboardDoubleArrowLeft
                className="start"
                onClick={() => onNext(1)}
              />
              <KeyboardArrowLeft
                className="start previous"
                onClick={() => onNext(page !== 1 ? page - 1 : 1)}
              />
              <span className="pagination-detail">
                Page {page} of {totalPage}
              </span>
              <KeyboardArrowRight
                className="last end"
                onClick={() => onNext(page !== totalPage ? page + 1 : page)}
              />
              <KeyboardDoubleArrowRight
                className="last"
                onClick={() => onNext(totalPage)}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
