import React, {useEffect, useState} from "react";
import "./Header.scss";
import Button from "../../components/button";
import {
    getStatus,
    BrowserNotificationHandler,
    redirectToMetamask,
} from "../../utils/Utility";
import {
    check_status,
    connect_wallet,
    req_id,
    swap_status,
} from "../../utils/Locale/en";
import Modal from "../../components/Modal";
import {errorToast, warningToast} from "../../utils/Toasts";
import TextInput from "../../components/TextInput";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {setAdd, setLanguage} from "../../features/bridgeSlice";
import useWalletConnection from "../../hooks/useConnection";

const Header = ({fixed = false}) => {
    const [modal, setModal] = useState(false);
    const [req, setReq] = useState("");
    const [status, setStatus] = useState("");
    const [loading, setLoading] = useState(false);
    const [statusCode, setStatusCode] = useState(null);
    const [balance, setBalance] = useState(null);
    const {connectWalletHandler, getTokenBalance} = useWalletConnection();
    const {networks, network} = useSelector((state: any) => state.bridge);
    // @ts-ignore
    let {ethereum = null, web3} = window;

    let {logo_url} = networks[network] || {};

    const pathName = window.location.pathname


    const {dark, publicAdd, blurBackground} = useSelector(
        (state: any) => state.bridge
    );

    const dispatch = useDispatch();

    const getStatusData = async () => {
        setLoading(true);
        try {
            if (req) {
                let res = await getStatus({request_id: req});
                if (res.data.code === 20005) {
                    setStatus(res.data.data.message);
                    if (window.innerWidth > 400) {
                        await BrowserNotificationHandler("Status", res.data.data.message);
                    }
                    setStatusCode(res.data.code);
                    setLoading(false);
                    return;
                }
                if (window.innerWidth > 400) {
                    await BrowserNotificationHandler("Status", res.data.data.message);
                }
                setStatus(res.data.data.message);
                setStatusCode(res.data.code);
                setLoading(false);
            } else {
                setLoading(false);
                warningToast("Please provide the request ID");
            }
        } catch (error) {
            setLoading(false);
            errorToast(error.message);
        }
    };


    const tokenBalance = async () => {
        let tokenBalance = await getTokenBalance();
        if (!!tokenBalance) {
            let bal = web3.utils.fromWei(tokenBalance.toString(), "ether");
            setBalance(bal);
        }
    };

    useEffect(() => {
        const boxes = document.querySelectorAll(".main_app");
        boxes.forEach((box: any) => {
            if (modal) {
                box.style.filter = "blur(8px)";
            } else {
                box.style.filter = "none";
            }
        });
    }, [modal]);

    useEffect(() => {
        if (ethereum) {
            ethereum.on("accountsChanged", (accounts) => {
                dispatch(setAdd(accounts[0]));
            });
        } else {
            if (pathName !== '/') {
                warningToast("Please install Metamask.");
            }

        }
    }, []);

    useEffect(() => {
        if (!!publicAdd) {
            if (!balance) {
                tokenBalance();
            }
            const time = setInterval(() => {
                tokenBalance();
            }, 10000)
            return (() => {
                clearInterval(time)
            })
        }
    }, [publicAdd]);

    const statusContent = (
        <div className={dark ? "swap_status dark" : "swap_status"}>
            <h1>{swap_status}</h1>
            <TextInput
                color={dark ? "#ffff" : "#231F20"}
                backgroundColor={dark ? "#1F1F1F" : "#ffff"}
                padding={10}
                fontSize={16}
                borderBottom={dark ? "1px solid #f7991c" : "1px solid #231F20"}
                placeholder={req_id}
                value={req}
                onChange={(val) => setReq(val)}
            />
            {status ? (
                <p>
                    <div
                        style={{
                            background:
                                statusCode === 20005 || statusCode === 20013
                                    ? "red"
                                    : statusCode === 20011
                                        ? "#ffa90c"
                                        : statusCode === 20012
                                            ? "green"
                                            : "red",
                            borderRadius: "50%",
                            width: 12,
                            height: 12,
                            display: "inline-block",
                            marginRight: 5,
                        }}
                    >
                        {" "}
                    </div>
                    {status}
                </p>
            ) : null}
            {window.innerWidth < 600 && (
                <Button
                    onClick={getStatusData}
                    fontSize={20}
                    fontWeight="400"
                    color="#231F20"
                    backgroundColor="#FFB048"
                    margin="20px 0px"
                    label={check_status}
                    loader={loading}
                    disabled={loading}
                />
            )}
        </div>
    );

    if (fixed) {
        return (
            <div className="w-screen h-[50px] flex items-center justify-center bg-[#091A00] p-0 m-0">
        <span
            className="text-[12px] text-white flex gap-2 items-center"
            onClick={connectWalletHandler}
        >
          <img src={"metaMask.png"} className="w-6 h-6"/>{" "}
            {publicAdd
                ? publicAdd.slice(0, 8) + "..." + publicAdd.slice(-8)
                : connect_wallet}
        </span>
            </div>
        );
    }

    return (
        <>
            <div
                className={dark ? "dark" : ""}
                style={{filter: blurBackground ? "blur(10px)" : "blur(0px)"}}
            >
                <div className="container-fluid">
                    <div className="nav_holder">
                        <div
                            className="metamask_connector"
                            style={{display: blurBackground ? "none" : "flex"}}
                        >
                            <div className="w-full flex justify-end h-20 items-center pt-8 gap-5">
                                {!!logo_url && !!publicAdd && !!balance && (
                                    <span
                                        className="rounded-[50px] border border-secondary flex items-center gap-3 px-6 py-2 text-base text-secondary cursor-pointer rainbow">
                    {<img src={logo_url} alt="imageLogo"/>}
                                        {balance}
                  </span>
                                )}
                                <span
                                    className="rounded-[50px] border border-secondary flex items-center gap-3 px-6 py-2 text-base text-secondary cursor-pointer rainbow"
                                    onClick={connectWalletHandler}
                                >
                  <img
                      src="/metaMask.png"
                      alt="metamask"
                      className="w-5 h-5"
                  />
                                    {publicAdd
                                        ? publicAdd.slice(0, 8) + "..." + publicAdd.slice(-8)
                                        : connect_wallet}
                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                modalIsOpen={modal}
                close={() => {
                    setLoading(false);
                    setReq("");
                    setStatus("");
                    setModal(false);
                }}
            >
                {statusContent}
            </Modal>
        </>
    );
};

export default Header;
