export const Headers_Labels = [
  {
    label: "TX Hash",
    value: "trx_id",
    redirect: true,
    redirectUrl: "redirected_url",
    width: 300,
  },
  {
    label: "Send Amount",
    value: "exact_send_amount",
    width: 150,
  },
  {
    label: "Recieved Amount",
    value: "exact_receivable_amount",
    width: 150,
  },
  {
    label: "From",
    value: "from",
    width: 90,
    align: "center",
    logoUrl: "from_logo_url"
  },
  {
    label: "",
    value: "transferIcon",
    width: 50,
  },
  {
    label: "To",
    value: "to",
    width: 90,
    align: "center",
    logoUrl: "to_logo_url"
  },
  {
    label: "Date",
    value: "date",
    width: 190,
  },
  // {
  //   label: "Status",
  //   value: "status",
  //   width: 110,
  // },
];

export const TitleStyle = "text-[32px] lg:text-[35px] font-semibold text-dark";
