import { Suspense } from "react";
import Box from "@mui/material/Box";
import { Toaster } from "react-hot-toast";
import useTheme from "../../theme/useThemeContext.hook";

import "./styles.scss";
import Navbar from "../nav";

const DefaultLayout = (props) => {
  const { isDark } = useTheme();

  return (
    <Suspense fallback={<div>Loading</div>}>
      <Box>
        <Toaster />
        <Navbar>
          <div>{props.children}</div>
        </Navbar>
      </Box>
    </Suspense>
  );
};
export default DefaultLayout;
