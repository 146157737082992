// Home
export const title_1 = "Swap PTH into"
export const title_2 = "No Cost"
export const title_3 = "PTH"
export const title_4 = "Into"
export const title_5 = "Other Networks"

export const description = "PTH Swap is a portal that allows users to easily swap PTH coins from its mainnet into another token from any of the supported blockchains from the world's top networks such as BSC."
export const AddToken = "Add Token"
export const subHeader_1 = 'Use PTH on the Top Blockchain Networks'
export const subHeader_21 = 'Through this swap portal, users can easily swap their PTH Coins into'
export const subHeader_22 = "PTH. Users have the option to convert it into the token standard of their choice from any of the supported blockchains from the world’s top networks"
export const homeIcon_1 = 'FAST'
export const homeIcon_2 = 'INTEROPERABLE'
export const homeIcon_3 = 'SECURED'
export const Swap = 'Swap'
export const From = 'From'
export const Max = 'Max'
export const connect_wallet_home = 'CONNECT WALLET'
export const connected_wallet_home = 'WALLET CONNECTED'
export const To = 'To'
export const gas_fee = 'Gas Fee'
export const ratio = 'PTH Ratio'
export const Receiving_Address = 'Receiving Address'
export const Receiving_Address_placeHolder_1 = 'Please enter your'
export const Receiving_Address_placeHolder_2 = 'address'
export const Terms_1 = 'By clicking ‘Swap’, you agree to our'
export const Terms_2 = 'Terms of Use'
export const Read_Manual = 'Read Manual'
export const choose_network = "Choose network"

// Swap Status
export const swap_status = 'Swap Status'
export const check_status = 'CHECK STATUS'
export const req_id = 'Please enter your request ID'

// Header
export const connect_wallet = 'Connect to wallet'
export const search_request = 'Search Request'

// Trans Screen
export const Back = 'Back'
export const send_exact = 'Send exactly'
export const to = 'to'
export const trans_desc_1 = 'PTH will send'
export const trans_desc_2 = 'Please confirm that you control this address'
export const Memo = 'Memo'
export const Memo_info_1 = "MEMO is required to deposit"
export const Memo_info_2 = "token to your account."
export const TXID = 'TXID'
export const TXID_placeholder = 'Please provide the TXID'
export const TXID_info_2 = 'Providing wrong transaction may result to permanent loss.'
export const TXID_info_1 = 'Please make sure you only paste'
export const TXID_info_3 =  'from your wallet transaction.'
export const Submit = 'SUBMIT'
export const URL = 'URL'
export const URL_info = "Please make sure you copy the URL if you wish to come back and finish the transfer later."

// Success
export const Trans_Complete = 'Transaction Complete'
export const Success_msg = 'Your swap request has been successfully processed. The transfer may still be in pending. Please check your wallet.'
export const Request_ID = 'Request ID'
export const Success_info = 'Please keep a copy of your request ID. This will allow you to send request to support incase of transaction failure'

// Fail
export const Trans_Failed = 'Transaction Failed'
