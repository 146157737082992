import React, { useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../components/button";
import TextInput from "../../components/TextInput";
import { TitleStyle } from "../../utils/common";
import { req_id } from "../../utils/Locale/en";
import { errorToast, warningToast } from "../../utils/Toasts";
import { BrowserNotificationHandler, getStatus } from "../../utils/Utility";
import WalletConnect from "../../components/wallet-connect";

export default function Status() {
  const [loading, setLoading] = useState(false);
  const [req, setReq] = useState("");
  const [status, setStatus] = useState("");
  const [statusCode, setStatusCode] = useState(null);

  const { publicAdd } = useSelector((state: any) => state.bridge);

  if (!!!publicAdd) {
    return <WalletConnect title={'Status'}/>
  }

  const getRequestStatus = async () => {
    setLoading(true);
    try {
      if (req) {
        let res = await getStatus({ request_id: req });
        if (res.data.code === 20005) {
          setStatus(res.data.data.message);
          if (window.innerWidth > 400) {
            await BrowserNotificationHandler("Status", res.data.data.message);
          }
          setStatusCode(res.data.code);
          setLoading(false);
          return;
        }
        if (window.innerWidth > 400) {
          await BrowserNotificationHandler("Status", res.data.data.message);
        }
        setStatus(res.data.data.message);
        setStatusCode(res.data.code);
        setLoading(false);
      } else {
        setLoading(false);
        warningToast("Please provide the request ID");
      }
    } catch (error) {
      setLoading(false);
      errorToast(error.message);
    }
  };

  return (
    <div className="w-full max-w-full pt-10">
      <span className={TitleStyle}>Status</span>
      <div className="text-base mt-4">
        Monitor the status of your Plastic Hero Bridge transactions.
      </div>
      <div className="w-full flex flex-col md:flex-row gap-4 items-center">
        <div className="border py-1.5 m-2 md:my-4 md:mx-0 w-full md:w-1/2 rounded-[12px]">
          <TextInput
            color={"#231F20"}
            backgroundColor={"#ffff"}
            padding={6}
            fontSize={16}
            zIndex={1}
            placeholder={req_id}
            value={req}
            onChange={(val) => {
              setReq(val);
              if (!!!val) setTimeout(() => setStatus(""), 500);
            }}
          />
        </div>
        <div className="">
          <Button
            loader={loading}
            disabled={!!!req}
            fontSize={14}
            width={200}
            label={"Search"}
            onClick={getRequestStatus}
          />
        </div>
      </div>
      <div className="mt-4 w-full border border-primary min-h-[200px] rounded-[12px] p-[20px] flex justify-center items-center">
        {status ? (
          <span className="text-[18px]">
            <div
              style={{
                background:
                  statusCode === 20005 || statusCode === 20013
                    ? "red"
                    : statusCode === 20011
                    ? "#ffa90c"
                    : statusCode === 20012
                    ? "green"
                    : "red",
                borderRadius: "50%",
                width: 12,
                height: 12,
                display: "inline-block",
                marginRight: 5,
              }}
            >
              {" "}
            </div>
            {status}
          </span>
        ) : null}
      </div>
    </div>
  );
}
