import { BrowserRouter, Switch, Route } from "react-router-dom";
import { ROUTES } from "./routes";
import DefaultLayout from "./components/default-layout";

import Home from "./screens/home/home";
import Transaction from "./screens/transaction";

import "./styles.scss";
import Manual from "./screens/manual";
import { useSelector } from "react-redux";
import useStartup from "./hooks/startup";
import { useEffect } from "react";
import TransactionHistory from "./screens/history";
import Status from "./screens/status";

const App = () => {
  const { loader } = useSelector((state: any) => state.bridge);
  const {} = useStartup();

  if (loader) {
    return (
      <div
        style={{
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div className={"lds-roller"}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
  return (
    <BrowserRouter>
      <Switch>
        <DefaultLayout>
          <Route path={ROUTES.default} exact children={<Home />} />
          <Route path={ROUTES.manual} exact children={<Manual />} />
          <Route path={ROUTES.history} children={<TransactionHistory />} />
          <Route path={ROUTES.transaction} children={<Transaction />} />
          <Route path={ROUTES.status} children={<Status />} />
        </DefaultLayout>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
