import Axios from "axios";
import { toast } from "react-toastify";
import Web3 from "web3";
import { crossABI, tokenABI } from "./ABI_constants";
import { BASE_URL } from "./constants";

// @ts-ignore
let { ethereum = null, web3 } = window;

export const checkEmailVerified = async (email) => {
  let data = await Axios.post(`${BASE_URL}/amountValidation/`, {
    email
  });
  return data;
};
export const getConversion = async (amount, deposit_currency, network,email) => {
  let data = await Axios.post(`${BASE_URL}/conversion/`, {
    deposit_currency,
    amount,
    network,
    email
  });
  return data;
};
export const swap = async (
  amount,
  address,
  deposit_currency,
  network,
  user_name,
  email
) => {
  const sendObj = {
    deposit_currency,
    amount,
    address,
    network,
    user_name,
    email
  };
  let data = await Axios.post(`${BASE_URL}/swap/`, sendObj);
  return data;
};
export const submit = async (params) => {
  let data = await Axios.post(`${BASE_URL}/submit/`, { ...params });
  return data;
};

export const getRequestDet = async (params) => {
  let data = await Axios.post(`${BASE_URL}/fetchrequest/`, { ...params });
  return data;
};
export const getStatus = async (params) => {
  let data = await Axios.post(`${BASE_URL}/check_status/`, { ...params });
  return data;
};
export const getNetworks = async () => {
  let data = await Axios.post(`${BASE_URL}/fetchnetwork/`, {});
  return data;
};

export const getTransactionsList = async (obj = {}) => {
  let data = await Axios.post(`${BASE_URL}/transactions/`, obj);
  return data;
};

export const getPermissionNotification = async () => {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  } else {
    await Notification.requestPermission();
  }
};
export function redirectToMetamask() {
  if (
    /* 'ontouchstart' in window || 'onmsgesturechange' in window ||  */ !ethereum
  ) {
    window.open("https://metamask.app.link/dapp/front1.abbcswap.com/");
    return true;
  } else {
    return false;
  }
}
export const BrowserNotificationHandler = async (header, body) => {
  if (!("Notification" in window)) {
    console.log("This browser does not support desktop notification");
  } else {
    // await Notification.requestPermission();
    var options = {
      body: body,
      icon: `/pthlogo.svg`,
      dir: "ltr",
    };
    // new Notification(header, options);
    new Notification(header);
  }
};

export const callWeb3Approval = async (
  contractAdd,
  walletAdd,
  crossAdd,
  network
) => {
  // @ts-ignore
  let myContract = new window.web3.eth.Contract(tokenABI, contractAdd);
  let check = await myContract.methods.allowance(walletAdd, crossAdd).call();
  return check;
};

export const callWeb3Balance = async (walletAdd, network, contractAdd) => {
  // @ts-ignore
  let myContract = new window.web3.eth.Contract(tokenABI, contractAdd);
  let bal = await myContract.methods.balanceOf(walletAdd).call();
  return bal;
};

export const callWeb3Decimals = async (network, contractAdd) => {
  // @ts-ignore
  let myContract = new window.web3.eth.Contract(tokenABI, contractAdd);
  let dec = await myContract.methods.decimals().call();
  return dec;
};

export const callWeb3SetLimit = async (
  contractAdd,
  amt,
  crossAdd,
  network,
  gasFee
) => {
  // @ts-ignore
  window.web3.eth.setProvider(Web3.givenProvider);
  // @ts-ignore
  let selected = window.web3.utils.toChecksumAddress(ethereum.selectedAddress);
  // @ts-ignore
  let myContract = new window.web3.eth.Contract(tokenABI, contractAdd);

  let limit = null;

  if (network === "MATIC") {
    limit = await myContract.methods.approve(crossAdd, amt.toString()).send({
      from: selected,
      maxPriorityFeePerGas: gasFee,
    });
    toast.success("Limit has been set. Swap Now.");

    return limit;
  }
  if (network === "FTM") {
    limit = await myContract.methods.approve(crossAdd, amt.toString()).send({
      from: selected,
      gasPrice: gasFee,
    });
    toast.success("Limit has been set. Swap Now.");

    return limit;
  }

  limit = await myContract.methods.approve(crossAdd, amt.toString()).send({
    from: selected,
  });
  toast.success("Limit has been set. Swap Now.");
  return limit;
  // let limit = await myContract.methods
  //     .approve(crossAdd, amt.toString())
  //     .send({ from: selected })

  // if (Object.keys(limit).length != 0) {
  //     toast.success("Limit has been set. Swap Now.")
  // }
  // return limit
};

export const callWeb3Swap = async (
  crossAdd,
  contractAdd,
  amount,
  toNetwork,
  userName,
  pthAddress,
  gasfee,
  network
) => {
  // @ts-ignore
  window.web3.eth.setProvider(Web3.givenProvider);
  // @ts-ignore
  let selected = window.web3.utils.toChecksumAddress(ethereum.selectedAddress);
  // @ts-ignore
  let estimatedGasFee = await window.web3.eth.estimateGas({
    from: selected,
    gasPrice: gasfee,
  });
  // @ts-ignore
  let myContract = new window.web3.eth.Contract(crossABI, crossAdd);

  if (network === "MATIC") {
    let swap = await myContract.methods
      .swapToken(
        web3.utils.toWei(amount.toString(), "ether"),
        userName,
        pthAddress
      )
      .send({ from: selected, maxPriorityFeePerGas: gasfee });
    return swap;
  }

  if (network === "FTM" || network === "BSC") {
    let swap = await myContract.methods
      .swapToken(
        // @ts-ignore
        window.web3.utils.toWei(amount.toString(), "ether"),
        userName,
        pthAddress
      )
      .send({
        from: selected,
        // gasPrice: gasfee,
        // gasLimit: estimatedGasFee * 3,
      });
    return swap;
  }

  if (network === "AWAX") {
    let swap = await myContract.methods
      .swapToken(
        web3.utils.toWei(amount.toString(), "ether"),
        userName,
        pthAddress
      )
      .send({
        from: selected,
        gasPrice: gasfee,
        gasLimit: estimatedGasFee * 3,
      });
    return swap;
  }

  let swap = await myContract.methods
    .swapToken(
      web3.utils.toWei(amount.toString(), "ether"),
      userName,
      pthAddress
    )
    .send({ from: selected });
  return swap;

  // let swap = await myContract.methods
  //     .swapToken(window.web3.utils.toWei(amount.toString(), 'ether'), userName, pthAddress)
  //     .send({ from: selected });
  // return swap
};


export const formatAdd = (add) => {
  console.log(add,"addressData", add.length)
  return add && add.length > 20 ? `${add.slice(0,6)}...${add.slice(-6)}`: add  
}