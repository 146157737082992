import React from "react";

export default function CustomSpinner() {
  return (
    <div
      style={{
        display: "flex",
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: 9999,
      }}
    >
      <div className={"lds-roller"}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}
