import React, { useRef, useState, useEffect } from "react";
import * as _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { formatUnits, parseUnits } from "@ethersproject/units";

import { toast } from "react-toastify";
import Axios from "axios";
import Web3 from "web3";

import "./styles.scss";
import Modal from "../../components/Modal";
import {
  AddToken,
  From,
  gas_fee,
  Max,
  ratio,
  Receiving_Address_placeHolder_1,
  Receiving_Address_placeHolder_2,
  To,
} from "../../utils/Locale/en";
import {
  blurBackground,
  setAdd,
  setBalance,
  setDecimal,
  setEmailData,
  setEmailVerified,
  setLimit,
  setNetwork,
} from "../../features/bridgeSlice";
import { useHistory } from "react-router";
import { errorToast, warningToast } from "../../utils/Toasts";
import { tokenABI } from "../../utils/ABI_constants";
import { Resposne } from "../../utils/ResponseDesc";
import {
  BrowserNotificationHandler,
  callWeb3Approval,
  callWeb3Balance,
  callWeb3Decimals,
  callWeb3SetLimit,
  callWeb3Swap,
  getConversion,
  redirectToMetamask,
  submit,
  checkEmailVerified,
  swap as swapCoin,
} from "../../utils/Utility";
import TextInput from "../../components/TextInput";
import Button from "../../components/button";
import StatusModal from "./component/statusModal";
import useWalletConnection from "../../hooks/useConnection";
import { Tooltip, useMediaQuery } from "@mui/material";
import TermsOfUse from "./terms";

const Home = () => {
  const {
    limit,
    dec,
    balance,
    transToLang,
    network: networkData,
    networks,
    publicAdd,
    emailVerified,
    emailData
  } = useSelector((state: any) => state.bridge);
  let history = useHistory();
  const [converted, setConverted] = useState(0.0);
  const [from, setFrom] = useState("PTH");
  const [fromLabel, setFromLabel] = useState("PTH");
  const [to, setTo] = useState("");
  const [toLabel, setToLabel] = useState("");
  const [gasFee, setGasFee] = useState("-");
  const [conversionRatio, setconversionRatio] = useState();
  const amount = useRef(null);
  const inputRef = useRef(null);
  const [userBPTH, setUserBPTH] = useState("");
  const [loader, setLoader] = useState(false);
  const [gasError, setGasError] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [rotate, setRotate] = useState(true);
  const [userName, setUserName] = useState("");
  const [title, setTitle] = useState("SET LIMIT");
  const [reqId, setReqId] = useState("");
  const [resCode, setResCode] = useState();
  const [transactionFailedMsg, setTransactionFailedMsg] = useState("");
  const [swapData, setSwapData] = useState(null);
  const dispatch = useDispatch();
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [transactionFailedAddress, setTransactionFailedAddress] = useState("");
  const [address, setAddress] = useState("");
  const isBrowser = useMediaQuery("(min-width:350px)");
  const { getTokenBalance } = useWalletConnection();
  const [termsOpen, setTermsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailVerifyLoading, setEmailVerifyLoading] = useState(false);

  // @ts-ignore
  let { ethereum = null, web3 } = window;
  const onVerifyClick = async (data)=>{
    try {
      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      let phoneRegex = /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
      if(data.match(validRegex)){
      setEmailVerifyLoading(true)
      const resp = await checkEmailVerified(data);
      dispatch(setEmailVerified(true))
      dispatch(setEmailData(resp.data.data))
      setEmailVerifyLoading(false)
    }else if (data.match(phoneRegex)) {
      
    }
    else{
      errorToast("Invalid Email/Phone Number")
    }
    } catch (error) {
      const errorMessage = error?.response?.data?.data?.key?`Invalid ${error?.response?.data?.data?.key}`
      :error?.response?.data?.data?error?.response?.data?.data:""
      !!errorMessage&&errorToast(errorMessage);
      setEmailVerifyLoading(false)
      
    }
  }
  useEffect(()=>{
    convert(emailData.input_amount,"PTH")
    const amountInput = document.getElementById("input_swap") as HTMLInputElement
    if(amountInput) amountInput.value = emailData.input_amount;
  },[emailData])
  useEffect(() => {
    if (transToLang == "kr") {
      warningToast("Coming Soon!");
    }
  }, [transToLang]);

  useEffect(() => {
    if (window.innerWidth < 575) {
      setAddress(`${publicAdd?.slice(0, 13)}...${publicAdd?.slice(-13)}`);
    } else {
      setAddress(publicAdd);
    }
  }, [publicAdd, window.innerWidth]);

  const checkApprovalLimit = async (from) => {
    try {
      if (publicAdd) {
        let limit = await callWeb3Approval(
          networks[from].token,
          publicAdd,
          networks[from].token_contract_Address,
          from
        );
        let balance = await callWeb3Balance(
          publicAdd,
          from,
          networks[from].token
        );
        let dec = await callWeb3Decimals(from, networks[from].token);
        balance = formatUnits(balance, dec);
        limit = formatUnits(limit, dec);
        dispatch(setBalance(balance));
        dispatch(setLimit(limit));
        dispatch(setDecimal(Number(dec)));
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const titleHandler = () => {
    // if (loader) {
    //     setTitle("Please wait")
    //     return
    // }
    if (limit == 0) {
      setTitle("SET LIMIT");
      return;
    }
    if (Number(limit) < Number(amount.current)) {
      setTitle("INCREASE LIMIT");
      return;
    }
    if (Number(limit) >= Number(amount.current)) {
      setTitle("SWAP");
      return;
    }
  };

  useEffect(() => {
    titleHandler();
  }, [amount.current, limit, loader]);

  useEffect(() => {
    setFrom("PTH");
    setFromLabel("PTH");
    setTo(networks[networkData]?.tokenName);
    setToLabel(networks[networkData]?.tokenName);
  }, [networkData]);

  useEffect(() => {
    if (fromLabel != "PTH") {
      checkApprovalLimit(networkData);
    }
  }, [publicAdd, networkData, amount.current]);

  const switchNetwork = async (val, status = false) => {
    if (ethereum) {
      const currentChainId = await ethereum.request({
        method: "eth_chainId",
      });
      if (currentChainId === networks[val]?.chainID) return true;
      if (!status) {
        if (ethereum.isCoin98) {
          errorToast("Please disable Coin98 extension");
        } else {
          try {
            await ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: networks[val]?.chainID,
                  chainName: networks[val]?.chainName,
                  rpcUrls: [networks[val]?.node],
                  nativeCurrency: {
                    name: networks[val]?.metamask_symbol,
                    symbol: networks[val]?.metamask_symbol, // 2-6 characters long
                    decimals: 18,
                  },
                  blockExplorerUrls: [networks[val]?.explorer_url],
                },
              ],
            });
            await ethereum.request({
              method: "wallet_switchEthereumChain",
              params: [{ chainId: networks[val]?.chainID }],
            });
            localStorage.setItem("pth$network", val);
            dispatch(setNetwork(val));
            convert(amount.current, from, val);
          } catch (error) {
            if (error.code === -32602) {
              await ethereum
                .request({
                  method: "wallet_switchEthereumChain",
                  params: [{ chainId: networks[val]?.chainID }],
                })
                .then((res) => {})
                .catch((e) => {
                  if (e.code == 4001) {
                    errorToast(e.message);
                  } else {
                    return;
                  }
                });
              localStorage.setItem("pth$network", val);
              dispatch(setNetwork(val));
              convert(amount.current, from, val);
              return;
            }
            if (error.code === -32002) {
              errorToast("Please confirm the pending request from metamask");
            } else {
              errorToast(error.message);
            }
          }
        }
      } else {
        warningToast("Under Maintenance. Sorry for the inconvenience caused.");
        setLoader(false);
      }
    } else {
      warningToast("Please install Metamask.");
      setLoader(false);
    }
  };

  useEffect(() => {
    switchNetwork(networkData);
  }, [networkData]);

  const convert = _.debounce(async (val, depCurr, networkVal = networkData) => {
    try {
      setLoader(true);
      if (val === "") {
        setLoader(false);
      }
      if (!isNaN(Number(val))) {
        if (Number(val) === 0) {
          setGasFee("-");
          setConverted(0);

          setLoader(false);
          return;
        }

        let convertRes = await getConversion(
          Number(val),
          (depCurr = depCurr === "PTH" ? depCurr : "BPTH"),
          networkVal,
          emailData.email||""
        );
        if (convertRes.data.code === 0) {
          setConverted(convertRes.data.data.receivable_amount);
          setGasFee(Number(convertRes.data.data.total_fee).toFixed(2));
          setconversionRatio(convertRes?.data?.data?.conversion_ratio || null);
          setLoader(false);
          setGasError("");
          return;
        }
        if (convertRes.data.code === 22023) {
          errorToast(convertRes.data.data.message);
          setLoader(false);

          return;
        }
        if (convertRes.data.code === 22024) {
          setGasError(convertRes.data.data.message);
          setLoader(false);

          return;
        }
        if (convertRes.data.code === 20020) {
          errorToast(convertRes.data.data.message);
          setLoader(false);
          return;
        }
        if (convertRes.data.code === 20001) {
          setGasError(convertRes.data.data.message);
          setconversionRatio(convertRes?.data?.data?.conversion_ratio || null);
          setConverted(0.0);
          setLoader(false);
          return;
        } else {
          warningToast(Resposne[convertRes.data.code].message);
          setConverted(0.0);
          setLoader(false);
          setGasError("");
        }
      } else {
        setGasError("Invalid Input");
        setLoader(false);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.data
      errorToast(!!errorMessage?errorMessage:error.message);
      setLoader(false);
      setGasError("");
    }
  }, 1000);

  useEffect(() => {
    let limit;
    if (ethereum) {
      // @ts-ignore
      window.web3 = new Web3(ethereum);
      ethereum.enable();
    }
  }, []);

  const swapFn = async () => {
    setLoader(true);
    if (!!!publicAdd) {
      setLoader(false);
      return walletConnectHandler();
    }
    try {
      if (!!!converted || isNaN(Number(converted))) {
        warningToast("Please enter valid amount");
        setLoader(false);
        return;
      }
      if (from === "PTH") {
        if (
          (from === "PTH" && !!publicAdd) ||
          (from !== "PTH" && userBPTH && userName)
        ) {
          let deposit_currency;
          let network = networkData;
          let publicadd;

          if (from === "PTH") {
            publicadd = web3.utils.toChecksumAddress(publicAdd);
            deposit_currency = "PTH";
          } else {
            publicadd = userBPTH;
            deposit_currency = networks[networkData].localName;
          }

          let swap = await swapCoin(
            Number(amount.current),
            publicadd,
            deposit_currency,
            network,
            userName,
            emailData.email||""
          );

          if (swap.data.code === 0) {
            history.push(
              `/trx/${swap.data.data.request_id}` /* , { state: { data: swap.data.data, coinid: from, deposit_currency, network } } */
            );
            setLoader(false);
          } else if (swap.data.code === 22025) {
            setAddressErr("Invalid Username/Wallet Address");
            setLoader(false);
          } else {
            errorToast(swap.data.data.message);
            setLoader(false);
          }
        } else {
          if (from === "PTH") {
            errorToast("Please Connect your wallet");
            setLoader(false);
          } else {
            setAddressErr("Please fill all fields");
            setLoader(false);
          }
        }
      } else {
        let gasfee;
        // if (networkData === "MATIC") {
        //   let gas = await Axios.get(
        //     "https://api.polygonscan.com/api?module=gastracker&action=gasoracle&apikey=WTMH6NW7W15SND2K5EFQD88VFIQTD1FK7F"
        //   );
        //   if (gas.status === 200) {
        //     let gasValue_Gwei =
        //       Number(Web3.utils.toWei(gas.data.result.FastGasPrice)) /
        //       1000000000;
        //     gasfee = gasValue_Gwei.toString();
        //   }
        // }
        // if (networkData === "FTM") {
        //   let gas = await Axios.get(
        //     "https://api.ftmscan.com/api?module=gastracker&action=gasoracle&apikey=GZZHIHDHGAFXUF6XIZYY83MUYAHABSCE5E"
        //   );
        //   if (gas.status === 200) {
        //     gasfee = (
        //       Number(
        //         Web3.utils.toWei(gas.data.result.ProposeGasPrice.toString())
        //       ) / 1000000000
        //     ).toString();
        //     let splitNumber = gasfee.toString().split(".");
        //     gasfee = Web3.utils.toBN(splitNumber[0]).toString();
        //   }
        // }

        if (networkData === "BSC") {
          let gas = await Axios.get(
            "https://api.bscscan.com/api?module=gastracker&action=gasoracle&apikey=5RHRZC37EFJ2F4GNHFTT86238Z8TE1C7HD"
          );
          if (gas.status === 200) {
            gasfee = (
              Number(
                Web3.utils.toWei(gas.data.result.ProposeGasPrice.toString())
              ) / 1000000000
            ).toString();
            let splitNumber = gasfee.toString().split(".");
            gasfee = Web3.utils.toBN(splitNumber[0]);
          }
        }
        // API Key
        // be941361d5944657a124f116b9b2c3fd
        // API Secret
        // 23a83d6c3e864a18ac00c95f9f72c1d7
        // if (networkData === "AVAX") {
        //   let gas = await Axios.get(
        //     "https://owlracle.info/avax/gas?apikey=be941361d5944657a124f116b9b2c3fd"
        //   );
        //   if (gas.status === 200) {
        //     // debugger
        //     gasfee = (
        //       Number(Web3.utils.toWei(gas.data.speeds[2].gasPrice.toString())) /
        //       1000000000
        //     ).toString();
        //     let splitNumber = gasfee.toString().split(".");
        //     gasfee = Web3.utils.toBN(splitNumber[0]);
        //   }
        // }

        let publicadd;
        let deposit_currency;
        publicadd = userBPTH;
        deposit_currency = networks[networkData].tokenName;
        let baseContract = networks[networkData].token;
        let baseFrom = networkData;
        let crossChainContract = networks[networkData].token_contract_Address;
        try {
          if (parseInt(amount.current) > parseInt(balance)) {
            errorToast("insufficient balance.");
            setLoader(false);
            return;
          }
          let inputAmount = parseUnits(
            amount.current
              .toString()
              .match(/^\d+(?:\.\d{0,18})?/)
              .toString(),
            dec
          );

          if (limit == 0) {
            let res = await callWeb3SetLimit(
              baseContract,
              inputAmount,
              crossChainContract,
              baseFrom,
              gasfee
            );
            await checkApprovalLimit(baseFrom);

            setLoader(false);

            return;
          }
          if (Number(limit) >= Number(amount.current)) {
            let swap = await swapCoin(
              Number(amount.current),
              publicadd,
              deposit_currency,
              network,
              userName,
              emailData.email||""
            );
            if (swap.data.code === 0) {
              setAddressErr("");
              setSwapData(swap.data.data);

              let res = await callWeb3Swap(
                crossChainContract,
                baseContract,
                swap.data.data.exact_amount,
                to,
                swap.data.data.request_id,
                userBPTH,
                gasfee,
                baseFrom
              );
              const {
                receipent_address,
                exact_amount,
                receivable_amount,
                request_id,
                network,
              } = swap.data.data;
              setReqId(request_id);
              if (res) {
                let params = {
                  receipent_address,
                  exact_amount,
                  receivable_amount,
                  request_id,
                  deposit_currency: "BPTH",
                  network,
                  trx_id: res.transactionHash,
                };
                let submitRes = await submit(params);
                setResCode(submitRes.data.code);
                if (submitRes.data.code === 20007) {
                  setLoader(false);
                  return;
                }
                if (
                  submitRes.data.code === 20004 ||
                  submitRes.data.code === 20006
                ) {
                  setLoader(false);
                  return;
                }
                if (submitRes.data.code === 20005) {
                  setLoader(false);
                  return;
                }
                if (submitRes.data.code === 20008) {
                  if (window.innerWidth > 400) {
                    await BrowserNotificationHandler(
                      "Fail",
                      submitRes.data.data.message
                    );
                  }
                  setTransactionFailedMsg(submitRes.data.data.message);
                  setTransactionFailedAddress(submitRes.data.data.address);

                  setTransactionStatus(true);
                  dispatch(blurBackground(true));
                  setLoader(false);
                  return;
                }
                if (submitRes.data.code === 0) {
                  setUserBPTH("");
                  setUserName("");
                  amount.current = "";
                  setConverted(0.0);

                  if (window.innerWidth > 400) {
                    await BrowserNotificationHandler(
                      "Success",
                      "Your swap request has been successfully processed. The transfer may still be in pending. Please check your wallet."
                    );
                  }
                  setTransactionStatus(true);
                  dispatch(blurBackground(true));

                  setLoader(false);
                  return;
                } else {
                  errorToast(submitRes.data.data.message);
                  setLoader(false);
                }
              }
              await checkApprovalLimit(baseFrom);
              setLoader(false);
            }
            if (swap.data.code === 22025) {
              setAddressErr("Invalid Username/Wallet Address");
              setLoader(false);
            } else {
              errorToast(swap.data.data.message);
              setLoader(false);
            }
            return;
          }
          if (Number(limit) <= Number(amount.current)) {
            let res = await callWeb3SetLimit(
              baseContract,
              inputAmount,
              crossChainContract,
              baseFrom,
              gasfee
            );
            await checkApprovalLimit(baseFrom);
            setLoader(false);
            return;
          }
        } catch (error) {
          if (
            error.message.includes("Transaction has been reverted by the EVM")
          ) {
            toast.error(
              "Transaction has been reverted by the EVM, please try again."
            );
            setLoader(false);
            return;
          } else if (
            error.message.includes(
              "Returned values aren't valid, did it run Out of Gas? You might also"
            )
          ) {
            toast.error(
              "Please try again later we are facing higher traffic on blockchain"
            );
            setLoader(false);
            return;
          } else if (error.message.includes("a is undefined")) {
            toast.error(
              "MetaMask Tx Signature: User denied transaction signature."
            );
            setLoader(false);
            return;
          } else if (
            error.message.includes("insufficient funds for transfer")
          ) {
            toast.error("insufficient funds for transfer.");
            setLoader(false);
            return;
          } else if (error.message.includes("insufficient funds for gas")) {
            toast.error("insufficient funds for gas.");
            setLoader(false);
            return;
          } else {
            toast.error(error.message);
            setLoader(false);
          }
          setLoader(false);
        }
      }
    } catch (error) {
      errorToast(error.message);
      setLoader(false);
      setLoader(false);
    }
  };

  const changeHandler = (val) => {
    setAddressErr("");
    if (amount) {
      checkApprovalLimit(networkData);
    }
    if (rotate) {
      (
        document.getElementsByClassName(
          "swapicon_holder"
        ) as HTMLCollectionOf<HTMLElement>
      )[0].style.transform = "translate(50%, -50%) rotate(180deg)";
    } else {
      (
        document.getElementsByClassName(
          "swapicon_holder"
        ) as HTMLCollectionOf<HTMLElement>
      )[0].style.transform = "translate(50%, -50%) rotate(0deg)";
    }
    let dep;
    if (from === "PTH") {
      setTo("PTH");
      setToLabel("PTH");
      setFrom(networks[networkData].tokenName);
      setFromLabel(networks[networkData].tokenName);
      dep = networks[networkData].localName;
    } else {
      setTo(networks[networkData].tokenName);
      setToLabel(networks[networkData].tokenName);
      setFrom("PTH");
      setFromLabel("PTH");
      dep = "PTH";
    }
    setRotate(!rotate);
    if (!isNaN(Number(amount.current)) && Number(amount.current) > 0) {
      convert(amount.current, dep);
    }
  };

  let { tokenName, localName, network, logo_url, crossLogo } =
    networks[networkData] || {};

  const walletConnectHandler = async () => {
    try {
      let res = await ethereum.enable();
      dispatch(setAdd(res[0]));
    } catch (error) {
      warningToast("Please install Metamask and connect your wallet.");
      setLoader(false);
    }
  };

  const addToken = async () => {
    let userAgent = navigator.userAgent;
    if (userAgent.match(/firefox|fxios/i)) {
      window.location.reload();
    }
    try {
      let check = redirectToMetamask();
      if (!check) {
        const { token, tokenName, logo_url, crossLogo } = networks[network];
        const tokenAddress = token;
        const tokenSymbol = tokenName;
        const tokenDecimals = 18;
        const tokenImage = logo_url;
        await ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20", // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: crossLogo, // A string url of the token logo
            },
          },
        });
      }
    } catch (error) {
      errorToast(error.message);
    }
  };

  const closeModal = () => {
    setTransactionStatus(false);
    dispatch(blurBackground(false));
    if (!!inputRef && !!inputRef.current) {
      inputRef.current.clear();
    }
  };

  const maxCoin = async () => {
    let tokenBalance = await getTokenBalance();
    if (tokenBalance) {
      (document.getElementById("input_swap") as HTMLInputElement).value =
        web3.utils.fromWei(tokenBalance.toString(), "ether");
      amount.current = formatUnits(tokenBalance, 18);
      convert(
        web3.utils.fromWei(tokenBalance.toString(), "ether"),
        networks[networkData].localName
      );
    }
  };

  console.log({ from });

  return (
    <>
      <div
        className="home_holder"
        style={{
          flex: 1,
          display: "flex",
          background: "#fff",
          filter: "blur(0px)",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <div className="home_container">
            <div className="home_left">
              <div className={`w-full md:w-2/3`}>
                <div className="flex flex-col pt-5">
                  <div className="text-[24px] md:text-[40px] font-semibold">
                    Exchange Your{" "}
                    {from === "PTH" ? (
                      <span className="text-dark">
                        Plastic Hero coins (PTH)
                      </span>
                    ) : (
                      <span className="text-dark">
                        Plastic Hero Token (PTH)
                      </span>
                    )}{" "}
                    for{" "}
                    {from !== "PTH" ? (
                      <span className="text-dark">
                        Plastic Hero coins (PTH)
                      </span>
                    ) : (
                      <span className="text-dark">
                        Plastic Hero Token (PTH)
                      </span>
                    )}
                  </div>
                  <span className="text-[14px] md:text-base pt-3">
                    Use the Plastic Hero Bridge to swap your hard-earned PTH
                    coins for Plastic Hero Token.
                  </span>
                </div>
                {emailVerified?<div className=" bg-[#FBF9F9] rounded-[30px] p-3 md:p-6 mt-8">You are eliglble to swap: 
                  <span className="text-[#4CB648] font-semibold">{` ${emailData.input_amount}`}</span>
                  <div className="w-full flex flex-col gap-[18px] relative">
                    <div className="rounded-[20px] w-full bg-[#ECECEC] p-[14px] md:p-[24px] relative">
                      <span className="text-[10px] text-secondary absolute top-2 left-[24px]">
                        {From}
                      </span>
                      <div className="grid grid-cols-12 md:grid-cols-3">
                        <div className="flex gap-1 h-full items-center col-span-5 md:col-span-1">
                          <img
                            src={from === "PTH" ? "/pthlogo.svg" : logo_url}
                            className="w-5 h-5"
                          />
                          <p>{fromLabel}</p>
                        </div>
                        <div className="col-span-7 md:col-span-2">
                          <div className="bg-white rounded-[10px] h-[50px] py-2 px-1 md:px-2 input-holder-home flex items-center">
                            <TextInput
                              id="input_swap"
                              color={"#231F20"}
                              ref={inputRef}
                              backgroundColor={"#FFFFFF"}
                              placeholder="0.00"
                              align="left"
                              onChange={(val) => {
                                amount.current = val;
                                from === "PTH"
                                  ? convert(val, from)
                                  : convert(val, localName);
                              }}
                              fontSize={!isBrowser ? 20 : 24}
                              type="number"
                              // disabled={!!!publicAdd}
                            />
                            {from !== "PTH" ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  cursor: "pointer",
                                  paddingLeft: "10px",
                                }}
                                onClick={() => maxCoin()}
                              >
                                <span className="locale-para px-1 md:px-2 text-[12px] md:text-[16px] flex items-center">
                                  {Max}
                                </span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="rounded-[20px] w-full bg-[#ECECEC] p-[14px] md:p-[24px]  relative margin-input">
                      <span className="text-[10px] text-secondary absolute top-2 left-[24px]">
                        {To}
                      </span>
                      <div className="grid grid-cols-12 md:grid-cols-3">
                        <div className="flex gap-1 h-full items-center col-span-5 md:col-span-1">
                          <img
                            src={to === "PTH" ? "/pthlogo.svg" : logo_url}
                            className="w-5 h-5"
                          />
                          <p>{toLabel}</p>
                        </div>
                        <div className="col-span-7 md:col-span-2">
                          <div className="bg-white rounded-[10px] h-[50px] p-2 flex items-center">
                            {!loader ? (
                              <span
                                className={`${
                                  !isBrowser ? "text-[20px]" : "text-[24px]"
                                } ${
                                  !!!Number(converted)
                                    ? "text-secondary"
                                    : "text-black"
                                } text-size-re`}
                              >
                                {!!Number(converted) ? converted : "0.00"}
                              </span>
                            ) : (
                              <span className="spinner"></span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="w-full h-full">
                      <Tooltip title="Token to Mainnet currently not available" placement="top">
                        <div
                          // onClick={changeHandler}
                          onClick={() => {}}
                          className={`swapicon_holder disabled`}
                        >
                          <img src={"./svgs/swap.svg"} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  {gasError ? (
                    <div className="error-text px-3">{gasError}</div>
                  ) : null}
                  <div className="address_holder">
                    <div className="network_holder  mb-2">
                      <span className="text-[12px] px-3">
                        {gasFee} PTH {gas_fee}
                      </span>
                      {!!conversionRatio && (
                        <p>
                          {ratio} : {conversionRatio}
                        </p>
                      )}
                    </div>
                    {from === "PTH" ? (
                      <div className={"meta_addressholder pt-3"}>
                        <div
                          className={"receiving_inputholder"}
                          style={{
                            backgroundColor: "#fff",
                          }}
                        >
                          <TextInput
                            label="Recipient"
                            id="input_swap"
                            color={"#231F20"}
                            backgroundColor={"#ffff"}
                            padding={12}
                            fontSize={16}
                            disabled={from === "PTH" ? true : false}
                            value={
                              !!publicAdd && from === "PTH"
                                ? address
                                : !!!publicAdd && from === "PTH"
                                ? ""
                                : userBPTH
                            }
                            placeholder={
                              from === "PTH"
                                ? `${Receiving_Address_placeHolder_1} ${
                                    tokenName || "PTH"
                                  } ${Receiving_Address_placeHolder_2}`
                                : `${Receiving_Address_placeHolder_1} PTH ${Receiving_Address_placeHolder_2}`
                            }
                            img={"/svgs/wallet.svg"}
                            onChange={(val) =>
                              from !== "PTH" && setUserBPTH(val)
                            }
                          ></TextInput>
                        </div>
                        {addressErr ? (
                          <p className="errorText">{addressErr}</p>
                        ) : null}
                      </div>
                    ) : (
                      Number(limit) >= Number(amount.current) && (
                        <div
                          className={
                            Number(limit) >= Number(amount.current) && "menu"
                          }
                        >
                          <div className="home_input_wrapper">
                            <div
                              style={{ width: "49%" }}
                              className={"meta_addressholder"}
                            >
                              <div
                                className={"receiving_inputholder"}
                                style={{
                                  backgroundColor: "#fff",
                                }}
                              >
                                <TextInput
                                  id="input_swap"
                                  label="Username"
                                  color={"#231F20"}
                                  backgroundColor={"#ffff"}
                                  padding={5}
                                  fontSize={16}
                                  placeholder="Enter Username "
                                  value={userName}
                                  onChange={(val) => setUserName(val)}
                                />
                              </div>
                            </div>

                            <div
                              style={{ width: "49%" }}
                              className={"meta_addressholder"}
                            >
                              <div
                                className={"receiving_inputholder"}
                                style={{
                                  backgroundColor: "#fff",
                                }}
                              >
                                <TextInput
                                  id="input_swap"
                                  label="PTH Address"
                                  color={"#231F20"}
                                  backgroundColor={"#ffff"}
                                  padding={5}
                                  fontSize={16}
                                  placeholder="Enter Wallet Address "
                                  value={userBPTH}
                                  disabled={
                                    title == "SET LIMIT" ||
                                    title == "INCREASE LIMIT"
                                      ? true
                                      : false
                                  }
                                  onChange={(val) => {
                                    if (from !== "PTH") {
                                      setUserBPTH(val);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          {addressErr ? (
                            <p style={{ margin: 0 }} className="errorText">
                              {addressErr}
                            </p>
                          ) : null}
                        </div>
                      )
                    )}
                    <div className="text-[12px] text-start w-full py-3 px-3">
                      By clicking ‘Swap’, you agree to our{" "}
                      <span
                        className="underline cursor-pointer"
                        onClick={() => setTermsOpen(true)}
                      >
                        Terms of Use
                      </span>
                      .
                    </div>
                    <div
                      className={`swap_buttonholder ${
                        title !== "SWAP NOW" && "menu"
                      }`}
                    >
                      <Button
                        loader={loader}
                        disabled={
                          !!!converted || isNaN(Number(converted)) || loader
                        }
                        fontSize={20}
                        fontWeight="600"
                        label={
                          publicAdd === ""
                            ? "Please Connect Your Wallet"
                            : fromLabel === "PTH"
                            ? "SWAP"
                            : title
                        }
                        onClick={swapFn}
                      />
                    </div>
                  </div>
                </div>:<div className=" bg-[#FBF9F9] rounded-[30px] p-3 md:p-6 mt-8">
                <div className="bg-white rounded-[10px] py-2 px-1 md:px-2 input-holder-home flex flex-col items-center gap-4">
                <div className="rounded-[20px] w-full bg-[#ECECEC] p-[14px] md:p-[24px] relative">
                      <span className="text-[10px] text-secondary absolute top-2 left-[24px]">
                        Email
                      </span>
                      <div className="grid grid-cols-12 md:grid-cols-3">
                        <div className="col-span-12 md:col-span-12">
                          <div className="bg-white rounded-[10px] h-[50px] py-2 px-1 md:px-2 input-holder-home flex items-center">
                          <TextInput
                              id="input_email"
                              color={"#231F20"}
                              backgroundColor={"#FFFFFF"}
                              placeholder="Enter your Email"
                              align="left"
                              value={email}
                              onChange={(e) => {
                                setEmail(e)
                              }}
                              fontSize={!isBrowser ? 20 : 24}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                            <Button
                        fontSize={20}
                        fontWeight="600"
                        label={"Verify" }
                        onClick={()=>onVerifyClick(email)}
                        loader={emailVerifyLoading}
                      />
                      <div className="flex flex-col  items-start w-full">
                      <span>Email: for AI Bots for mining</span>
                      
                      </div>
                 </div>
                </div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal close={closeModal} modalIsOpen={transactionStatus}>
        <StatusModal
          swapData={swapData}
          transactionFailedMsg={transactionFailedMsg}
          resCode={resCode}
          transactionFailedAddress={transactionFailedAddress}
          reqId={reqId}
          closeModal={closeModal}
          transaction={true}
        />
      </Modal>
      {termsOpen && (
        <Modal close={() => setTermsOpen(false)} modalIsOpen={termsOpen}>
          <TermsOfUse />
        </Modal>
      )}
    </>
  );
};

export default Home;
