import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import { setLoad, setNetwork, setNetworks } from "../features/bridgeSlice";
import { errorToast } from "../utils/Toasts";
import { getNetworks, getPermissionNotification } from "../utils/Utility";

const useStartup = () => {
  const dispatch = useDispatch();
  const { networks, network } = useSelector((state: any) => state.bridge);

  const getNetworkList = async () => {
    try {
      let res = await getNetworks();
      if (res.data.code !== 0) {
        errorToast("Failed to fetch networks");
        return;
      }
      let network_list = {};
      res.data.data.map((ele) => {
        network_list[ele.network] = { ...ele };
      });
      let localNetwork = localStorage.getItem("pth$network");
      if (!(localNetwork in network_list)) {
        dispatch(setNetwork(Object.keys(network_list)[0]));
      }
      dispatch(setNetworks(network_list));
    } catch (error) {
      errorToast(error.message);
    }
  };
  const getBrowserNoti = async () => {
    await getPermissionNotification();
  };

  useEffect(() => {
    getNetworkList();
  }, []);

  useEffect(() => {
    if (network in networks) {
      // @ts-ignore
      window.web3 = new Web3(new Web3.providers.HttpProvider(networks[network].node));
    }
    if (Object.keys(networks).length > 0) {
      setTimeout(() => {
        dispatch(setLoad(false));
        getBrowserNoti();
      }, 500);
    }
  }, [network, networks]);

  return {};
};

export default useStartup;
