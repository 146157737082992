import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {setAdd} from "../features/bridgeSlice";
import {tokenABI} from "../utils/ABI_constants";
import {errorToast, warningToast} from "../utils/Toasts";
import {redirectToMetamask} from "../utils/Utility";
import {ethers} from "ethers";

export default function useWalletConnection() {
    // @ts-ignore
    const {ethereum = null} = window;
    const {publicAdd, networks, network} = useSelector(
        (state: any) => state.bridge
    );
    const dispatch = useDispatch();

    const getConnectedNetwork = () => {
        if (ethereum) {
            return Number(ethereum.networkVersion)
        }
    }

    const connectWalletHandler = async () => {
        if (ethereum) {
            if (ethereum.isCoin98) {
                errorToast("Please disable Coin98 extension");
            } else {
                try {
                    let check = redirectToMetamask();
                    if (!check) {
                        let res = await ethereum.enable();
                        dispatch(setAdd(res[0]));
                    }
                } catch (error) {
                    warningToast("Please install Metamask and connect your wallet.");
                }
            }
        }
    };

    const getTokenBalance = async () => {
        try {
            if (publicAdd) {
                const selectedChain = getConnectedNetwork()
                const networkChain = parseInt(networks[network].chainID, 16)
                if (selectedChain !== networkChain) {
                    return 0
                }
                // @ts-ignore
                let contract = new window.web3.eth.Contract(
                    tokenABI,
                    networks[network].token
                );
                if (!!contract) {
                    let balance = await contract.methods.balanceOf(publicAdd).call();
                    return balance;
                }

            } else {
                errorToast("Please connect your wallet", {duration: 1000});
            }
        } catch (error) {
            errorToast(error.message);
        }
    };

    return {
        connectWalletHandler,
        getTokenBalance,
    };
}
