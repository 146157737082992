import React from 'react'
import {termsArray} from "./constants";
import './styles.scss'

export default function TermsOfUse() {
    const {title: mainTitle = '', header: mainDescription = '', content = [], footer = ''} = termsArray
    return (
        <div className={'main-term-card'}>
            <div>
                <div className={'main-title'}>
                    {mainTitle}
                </div>
                <div>
                    {mainDescription}
                </div>
            </div>
            <div>
                {content.map(item => {
                    const {title = '', descr = ''} = item
                    return <div>
                        <div className={'title'}>
                            {title}
                        </div>
                        <div className={'description'}>
                            {descr}
                        </div>
                    </div>
                })}
            </div>
            <div className={'footer'}>
                {footer}
            </div>
        </div>
    )
}
