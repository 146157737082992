import React from 'react'
import {TitleStyle} from "../../utils/common";

import './styles.scss'

const WalletConnect = ({title = ''}) => {
    return <div className="w-full pt-10">
        <span className={TitleStyle}>{title}</span>
        <div className="w-full mt-40 justify-center flex-col h-20 flex items-center border-primary">
            <img src={'./svgs/wallet-connect.svg'} width={120}/>
            <span className="text-[20px] mt-5 font-semibold empty-text">
            Please Connect to wallet
          </span>
            <div className={'description-empty'}>
                Please Connect the wallet by clicking on above wallet icon.
            </div>
        </div>
    </div>
}

export default WalletConnect