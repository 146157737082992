import React, {useEffect, useMemo, useState} from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

import {Link, useLocation} from "react-router-dom";
import {NAV_ROUTES, ROUTES} from "../../routes";
import {useDispatch, useSelector} from "react-redux";

import "./styles.scss";
import {Container, useMediaQuery} from "@mui/material";
import MobileNavigation from "./mobileNavigation";
import Header from "../../screens/header";
import useWalletConnection from "../../hooks/useConnection";

const Navbar = ({children}) => {
    const [open, setOpen] = useState(false);
    // const isMobile = window.innerWidth <= 760;
    const isMobile = useMediaQuery("(max-width: 760px)");
    const location = useLocation();
    const pathName = location.pathname;


    const maintain = useMemo(()=> <>
        {children}</>,[children])

    if (isMobile) {
        return (
            <MobileNavigation
                drawerItem={
                    <NavItems isMobile={isMobile} setOpen={setOpen} pathName={pathName}/>
                }
                open={open}
                setOpen={setOpen}
            >
                {maintain}
            </MobileNavigation>
        );
    }

    return (
        <div className="flex navBar-layout min-h-screen">
            <div className="nav-bar-styles">
                <div className="flex flex-col gap-0 items-center mt-14">
                    <Link
                        to={ROUTES.default}
                        className={"py-1 px-2 bg-dark rounded-[10px]"}
                    >
                        <img className={"logo-image"} src={"/svgs/logo.svg"}/>
                    </Link>
                    <span className="text-[16px] text-dark">BRIDGE</span>
                </div>
                <NavItems isMobile={isMobile} pathName={pathName}/>
            </div>
            <div className="w-full">
                <div className="px-2 md:px-10 lg:px-20 xl:px-32">
                    <Header/>
                    {maintain}
                </div>
            </div>
        </div>
    );
};

export default Navbar;

export const NavItems = ({isMobile, setOpen = null, pathName}) => {
    // @ts-ignore
    let {ethereum = null, web3} = window;
    const [balance, setBalance] = useState(null);
    const {dark, publicAdd, blurBackground, networks, network} = useSelector(
        (state: any) => state.bridge
    );
    const {connectWalletHandler, getTokenBalance} = useWalletConnection();
    let {logo_url} = networks[network] || {};


    const tokenBalance = async () => {
        let tokenBalance = await getTokenBalance();
        if (!!tokenBalance) {
            let bal = web3.utils.fromWei(tokenBalance.toString(), "ether");
            setBalance(bal);
        }
    };

    useEffect(() => {
        if (!!publicAdd) {
            if (!balance) {
                tokenBalance();
            }
            const time = setInterval(() => {
                tokenBalance();
            }, 10000)
            return (() => {
                clearInterval(time)
            })
        }
    }, [publicAdd]);

    return (
        <div>

            <div className="flex flex-col gap-2 mt-0 md:mt-10">
                {isMobile && <div className="flex flex-col gap-0 items-center mt-4">
                    <Link
                        to={ROUTES.default}
                        className={"py-1 px-2 bg-dark rounded-[10px]"}
                    >
                        <img className={"logo-image"} src={"/svgs/logo.svg"}/>
                    </Link>
                    <span className="text-[16px] text-dark">BRIDGE</span>
                </div>}
                {!!logo_url && !!publicAdd && !!balance && isMobile && (
                    <span
                        className="m-2 rounded-[8px] border border-secondary flex items-center gap-3 px-6 py-2 text-base text-secondary cursor-pointer rainbow">
                    {<img src={logo_url} alt="imageLogo" className={'w-8'}/>}
                        {balance}
                  </span>
                )}
                {NAV_ROUTES.map((item) => {
                    const {route = "/"} = item;
                    const isActive = route === pathName;
                    return (
                        <React.Fragment key={`nav-button-${item.label}`}>
                            <RouteButton
                                isActive={isActive}
                                {...item}
                                isMobile={isMobile}
                                setOpen={setOpen}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

const RouteButton = ({
                         route,
                         isActive,
                         label,
                         extraClass = "",
                         icon = "",
                         iconDark = '',
                         isMobile = true,
                         setOpen,
                     }) => {
    return (
        <Link
            key={`nav-button-${route}`}
            className={`${extraClass}`}
            to={route}
            onClick={() => {
                window.location.pathname !== route &&
                window.scrollTo({top: 0, behavior: "smooth"});
                isMobile && setOpen && setOpen(false);
            }}
        >
            <div
                className={`px-8 md:px-12 py-3 flex gap-2 mobile-drawer ${
                    isActive
                        ? "bg-gradient-to-r from-white text-[#4CB648] font-semibold"
                        : "text-secondary"
                } ${isMobile ? "text-[16px] font-semibold gap-4" : "text-[16px]"}`}
            >
                <img src={isActive ? iconDark : icon} style={{color: "red"}} width={isMobile ? 18 : 15}/>
                {label}
            </div>
        </Link>
    );
};
