import React from 'react'
import { toast } from 'react-hot-toast';

export const successToast=(message)=>{
    toast.success(message);
}
export const errorToast=(message, options = {})=>{
    toast.error(message );
}
export const warningToast=(message)=>{
    toast.custom(<div className='text-warning'>{message}</div>);
}