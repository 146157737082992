import copy from "copy-to-clipboard";
import React from "react";
import Button from "../../../components/button";
import {
  Success_info,
  Success_msg,
  Trans_Complete,
} from "../../../utils/Locale/en";
import { successToast } from "../../../utils/Toasts";
import {useSelector} from "react-redux";

export default function StatusModal({
  swapData = null,
  resCode,
  transactionFailedMsg,
  transactionFailedAddress,
  reqId,
  closeModal,
  transaction = false
}) {

  const { network: networkData, networks } = useSelector((state: any) => state.bridge);

  return (
    <div className={"Success"}>
      {resCode === 20008 ? (
        <>
          <div>
            <img src="/svgs/close.svg" className={'w-[20px] md:w-[30px]'}/>
            <span className="text-[20px] md:text-[28px] font-semibold pl-2 text-error">
              Transaction Failed
            </span>
          </div>
          <p>
            Transaction id validation failed. You have entered incorrect AMOUNT
            and MEMO details, requested amount will be refunded to
          </p>
          <div className="bg-[#ECECEC] p-[20px] mb-6 mt-3 rounded-[15px] error-content">
            <span className="text-error text-base font-medium m-0">
              {transactionFailedAddress}
            </span>
            <span className="text-base font-semibold m-0 font-black">Request ID</span>
            <div className="text-[13px] font-semibold m-0 border border-[#9E9E9E] rounded-[12px] p-3 flex flex-col gap-3">
              {reqId}
              <img src={'/svgs/copy.svg'}  onClick={() => {
                copy(reqId);
                successToast("Copied");
              }} />
            </div>
          </div>
        </>
      ) : (
        resCode === 0 && (
          <>
            <div>
              <img src={"/svgs/tick.svg"} className={'w-[20px] md:w-[30px]'} />
              <span className="text-[20px] md:text-[28px] font-semibold pl-2 text-dark">
                Swap Successful
              </span>
            </div>
            <p style={{ color: "#000000" }}>{Success_msg}</p>

            {!!swapData ? (
              <div className="modalInfo">
                <div className="modalContainer">
                  <div className="modalContWrapper">
                    <span className="text-[12px] text-secondary">From</span>
                    <span className="font-base color-black font-semibold">{`${swapData?.exact_amount} ${transaction ? networks[networkData]?.tokenName || "PTH-Token" : "PTH"}`}</span>
                  </div>
                  <div className="successSwapicon">
                    <img src={"/svgs/interchange.svg"} />
                  </div>
                  <div className="modalContWrapper">
                    <span className="text-[12px] text-secondary">To</span>
                    <span className="font-base color-black font-semibold">{`${swapData?.receivable_amount} ${transaction ? "PTH" : networks[networkData]?.tokenName || "PTH-Token" }`}</span>
                  </div>
                </div>
              </div>
            ) : null}

            <div></div>
            <div
              className={"status_modal sender_address"}
              style={{
                transition: "all 0s",
                justifyContent: "center",
                display: "block",
                padding: 8,
              }}
            >
              <div className={"sender_logoholder"}>
                  <span>{`${reqId}`}</span>
              </div>
              <img src={'/svgs/copy.svg'}      onClick={() => {
                copy(reqId);
                successToast("Copied");
              }}/>
            </div>
          </>
        )
      )}
      <p style={{ color: "#9E9E9E" }}>{Success_info}</p>
      <div className="">
        <Button label={"CLOSE"} fontWeight={'600'}  onClick={closeModal} />
      </div>
    </div>
  );
}
