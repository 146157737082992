import React, {useEffect, useState} from "react";
import QRCode from "react-qr-code";
import {useHistory, useParams} from "react-router";
import {connect, useDispatch, useSelector} from "react-redux";
import {BiArrowBack, BiCopyAlt} from "react-icons/bi";
import copy from "copy-to-clipboard";
import {ImQrcode} from "react-icons/im";
import ReactTooltip from "react-tooltip";
import {AiOutlineCloseCircle} from "react-icons/ai";
import {blurBackground, setNetwork} from "../../features/bridgeSlice";
import {
    Back,
    Memo,
    Memo_info_1,
    Memo_info_2,
    Receiving_Address,
    send_exact,
    Submit,
    Success_info,
    Success_msg,
    to,
    Trans_Complete,
    trans_desc_1,
    trans_desc_2,
    TXID,
    TXID_info_1,
    TXID_info_2,
    TXID_info_3,
    TXID_placeholder,
    URL_info,
} from "../../utils/Locale/en";
import {
    BrowserNotificationHandler,
    getRequestDet,
    submit,
} from "../../utils/Utility";
import {errorToast, successToast} from "../../utils/Toasts";
import Button from "../../components/button";
import TextInput from "../../components/TextInput";

import "./styles.scss";
import Modal from "../../components/Modal";
import StatusModal from "../home/component/statusModal";
import CustomSpinner from "../../components/spinner";

const Transaction = (props) => {
    const [trxid, setTrx] = useState("");
    const [exact_amount, setexact_amount] = useState("");
    const [receivable_amount, setreceivable_amount] = useState("");
    const [deposit_currency, setdeposit_currency] = useState("");
    const [TSSadd, setTSSadd] = useState("");
    const [memo, setMemo] = useState("");
    const [receipent_address, setreceipent_address] = useState("");
    const [network, setnetwork] = useState("");
    const [loader, setLoader] = useState(false);
    const [trxError, setTrxError] = useState(null);
    const [urlModal, setURLModal] = useState(false);
    const [tooltip, setToolTip] = useState(false);
    const [transactionStatus, setTransactionStatus] = useState(false);
    const [transactionFailedMsg, setTransactionFailedMsg] = useState("");
    const [transactionFailedAddress, setTransactionFailedAddress] = useState("");
    const [resCode, setResCode] = useState();
    const [loading, setLoading] = useState(true);
    const {dark, networks} = useSelector((state: any) => state.bridge);

    let history = useHistory();
    let paramsURL: any = useParams();
    let request_id = paramsURL.id;
    const dispatch = useDispatch();

    const getReqDetails = async () => {
        try {
            let req_data = await getRequestDet({request_id: paramsURL.id});
            if (req_data?.data?.code === 0) {
                let data = req_data?.data?.data || {};
                setMemo(data?.memo);
                setexact_amount(data?.exact_amount);
                setreceivable_amount(data?.receivable_amount);
                setdeposit_currency(data?.deposit_currency);
                setTSSadd(data["TSS address"]);
                setreceipent_address(data?.receipent_address);
                setnetwork(data?.network);
                dispatch(setNetwork(data?.network));
                setLoading(false);
                return;
            } else {
                errorToast(req_data?.data?.data?.status);
                setLoading(false);
                history.push("/");
            }
        } catch (error) {
            setLoading(false);
            errorToast(error.message);
        }
    };

    useEffect(() => {
        getReqDetails();
    }, []);

    useEffect(() => {
        if (!paramsURL.id) history.push("/");
    }, [paramsURL]);

    const submitData = async () => {
        setLoader(true);
        try {
            if (paramsURL?.id && receipent_address && trxid) {
                let params = {
                    receipent_address,
                    exact_amount,
                    receivable_amount,
                    request_id,
                    "TSS address": TSSadd,
                    deposit_currency:
                        deposit_currency === "PTH" ? deposit_currency : "BPTH",
                    network,
                    memo,
                    trx_id: trxid,
                };
                let submitRes = await submit(params);
                setResCode(submitRes?.data?.code);
                if (submitRes?.data?.code === 20007) {
                    setTrxError("Invalid Transaction ID");
                    setLoader(false);
                    return;
                }
                if (submitRes?.data?.code === 20004 || submitRes?.data?.code === 20006) {
                    setTrxError("Transaction ID already exists.");
                    setLoader(false);
                    return;
                }
                if (submitRes?.data?.code === 20005) {
                    setTrxError("Invalid Request ID");
                    setLoader(false);
                    return;
                }
                if (submitRes?.data?.code === 20008) {
                    if (window.innerWidth > 400) {
                        await BrowserNotificationHandler(
                            "Fail",
                            submitRes?.data?.data.message
                        );
                    }
                    setTransactionFailedMsg(submitRes?.data?.data.message);
                    setTransactionFailedAddress(submitRes?.data?.data?.address);
                    dispatch(blurBackground(true));
                    setTransactionStatus(true);
                    setLoader(false);
                    return;
                }
                if (submitRes?.data?.code === 0) {
                    if (window.innerWidth > 400) {
                        await BrowserNotificationHandler(
                            "Success",
                            "Your swap request has been successfully processed. The transfer may still be in pending. Please check your wallet."
                        );
                    }
                    setTransactionStatus(true);
                    dispatch(blurBackground(true));

                    setLoader(false);
                    return;
                } else {
                    errorToast(submitRes?.data?.data?.message);
                    setTrxError(null);
                    setLoader(false);
                }
            } else {
                setTrxError(null);
                setLoader(false);
            }
        } catch (error) {
            errorToast(error.message);
            setLoader(false);
        }
    };

    const closeModal = () => {
        setTransactionStatus(false);
        dispatch(blurBackground(false));
        history.push("/");
    };

    return (
        <>
            {loading && <CustomSpinner/>}

            {deposit_currency === "PTH" && (
                <div className={"trans_holder"}>
                    <div className="grid grid-cols-2 pt-10 lg:pt-0">
                        <div className="col-span-2 lg:col-span-1">
                            <div className="">
                                <div className={"back_button"}>
                                    <div onClick={() => history.push("/")}>
                                        <BiArrowBack color="#231F20"></BiArrowBack>
                                        <p>{Back}</p>
                                    </div>
                                </div>
                                <div className={"send_addressholder"}>
                  <span className="text-[18px] flex gap-2 items-center width-wrap">
                    {send_exact}{" "}
                      <span className="text-primary font-semibold flex gap-2 items-center">
                      {exact_amount}{" "}
                          <div
                              className="copy-btn"
                              onClick={() => {
                                  copy(exact_amount);
                                  successToast("Copied");
                              }}
                          >
                        <img src="/svgs/copy.svg" alt="copy-text"/>
                      </div>
                    </span>{" "}
                      {deposit_currency} {to}
                  </span>
                                    <div className={"sender_address"}>
                    <span className="label-input label-hard">
                      {Receiving_Address}
                    </span>
                                        <div className={"sender_logoholder"}>
                                            {window.innerWidth < 400 && TSSadd && TSSadd?.length > 20 ? (
                                                <p>{`${TSSadd.slice(0, 8)}...${TSSadd.slice(-8)}`}</p>
                                            ) : (
                                                <p>{`${TSSadd}`}</p>
                                            )}
                                        </div>
                                        <div
                                            className="copy-btn copy"
                                            onClick={() => {
                                                copy(TSSadd);
                                                successToast("Copied");
                                            }}
                                        >
                                            <img src="/svgs/copy.svg" alt="copy-text"/>
                                        </div>
                                    </div>
                                    <div className={"qr_holder full-holder  mx-2 md:mx-4"}>
                                        <p style={{fontSize: 14}}>
                                            {trans_desc_1}{" "}
                                            <span className="text-primary">{receivable_amount}</span>{" "}
                                            {network && networks[network].tokenName} {to}{" "}
                                            {receipent_address.length > 12
                                                ? receipent_address.slice(0, 4) +
                                                "..." +
                                                receipent_address.slice(-4)
                                                : receipent_address}{". "}
                                            <div>
                                                <p style={{fontSize: 14}}>{trans_desc_2}</p>
                                            </div>
                                        </p>

                                    </div>
                                </div>
                            </div>
                            <div className="transright_holder">
                                <div className={"txid_holder"}>
                                    <div className={"memo_holder"}>
                                        <div className="memo">
                                            <span className="label-input label-hard">{Memo}</span>
                                            <p>{memo}</p>
                                        </div>
                                        <div className={'mx-2 md:mx-4'}>
                                         <span className="text-[14px]">
                      {Memo_info_1}{" "}
                                            <span className="text-primary font-semibold">
                        {network && networks[network].tokenName}
                      </span>{" "}
                                            {Memo_info_2}
                    </span></div>
                                    </div>

                                    <Modal
                                        close={() => setURLModal(false)}
                                        modalIsOpen={urlModal}
                                    >
                                        <div className={"txid_holder"}>
                                            <h1>URL</h1>
                                            <div className={"sender_address"}>
                                                <div className={"sender_logoholder"}>
                                                    <img src={`/pthlogo.svg`}></img>
                                                    <p>{`${window.location.origin}/trans/${request_id}`}</p>
                                                </div>
                                                <BiCopyAlt
                                                    size={25}
                                                    onClick={() => {
                                                        copy(
                                                            `${window.location.origin}/trans/${request_id}`
                                                        );
                                                        successToast("Copied");
                                                    }}
                                                    color="#B2B2B2"
                                                ></BiCopyAlt>
                                            </div>
                                            <div className="qr_holder">
                                                <p>{URL_info}</p>
                                                <div className="qr_border">
                                                    <QRCode
                                                        size={100}
                                                        value={`${window.location.origin}/trans/${request_id}`}
                                                    ></QRCode>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>

                                    <div className={"receiving_inputholder flex items-center"}>
                                        <TextInput
                                            label="TXID"
                                            placeholder={TXID_placeholder}
                                            marginRight={10}
                                            color={"#231F20"}
                                            backgroundColor={"#ffff"}
                                            padding={12}
                                            fontSize={16}
                                            zIndex={1}
                                            error={trxError}
                                            onChange={(val) => {
                                                setTrx(val);
                                            }}
                                        />
                                        <ReactTooltip
                                            place="top"
                                            id="qr"
                                            type="light"
                                            effect="solid"
                                        >
                                            {/* <div className='qr_border'> */}
                                            <QRCode
                                                size={100}
                                                value={`${window.location.origin}/trx/${request_id}`}
                                            ></QRCode>
                                            {/* </div> */}
                                        </ReactTooltip>

                                        <img  data-tip
                                              className={'mr-2'}
                                              data-for="qr" src={'/svgs/QR_code.png'}  onMouseEnter={() => {
                                            setToolTip(true);
                                        }}
                                             onMouseLeave={() => {
                                                 setToolTip(false);
                                             }} />
                                    </div>
                                    {trxError ? <div className="error-text px-3">{trxError}</div> : null}
                                    <div className="text-[14px] mx-2 md:mx-4">
                                        {TXID_info_1}{" "}<span
                                        className="text-dark font-semibold">{TXID}</span>{" "}{TXID_info_3}{" "}
                                        <span className="text-dark">{TXID_info_2}</span>
                                    </div>
                                    <div style={{paddingTop: 20}}>
                                        <Button
                                            disabled={loader}
                                            loader={loader}
                                            fontSize={20}
                                            fontWeight="600"
                                            color="#fff"
                                            label={Submit}
                                            onClick={submitData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-span-2 lg:col-span-1">*/}
                        {/*    <div className={"left_section lg:ml-10"}>*/}
                        {/*        <div className={"qr_section"}>*/}
                        {/*            <div className={"qr_holder"} style={{marginLeft: 0}}>*/}
                        {/*                <div className="qr_border">*/}
                        {/*                    <QRCode*/}
                        {/*                        size={100}*/}
                        {/*                        value={`abbc:${TSSadd}?amount=${exact_amount}`}*/}
                        {/*                    ></QRCode>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*            <p>Please scan the QR code in Aladdin Pro</p>*/}
                        {/*        </div>*/}
                        {/*        <div style={{marginTop: 20, fontSize: 14}}>*/}
                        {/*            <p style={{color: "#000"}}>*/}
                        {/*                Alternatively, you can open your Aladdin Pro Wallet app and*/}
                        {/*                use the QR code scanner to automatically fill in your PTH*/}
                        {/*                Master Wallet Address and exact PTH amount.*/}
                        {/*            </p>*/}
                        {/*        </div>*/}
                        {/*        <div className={"left_list"}>*/}
                        {/*            <li>*/}
                        {/*                In order to use this feature, you must have the Aladdin Pro*/}
                        {/*                Wallet app downloaded on your mobile device.*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                Once downloaded, open the Aladdin Pro Wallet app (make sure*/}
                        {/*                you have the latest version of the application).*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                The Aladdin Pro Wallet app will display all the different*/}
                        {/*                options of coins to choose from. Choose PTH Coin.*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                Once inside the PTH Coin page, find the QR code symbol on*/}
                        {/*                the top right of the screen beside ‘Total Balance.’*/}
                        {/*            </li>*/}
                        {/*            <li>*/}
                        {/*                Open the QR code scanner to scan the QR code from this*/}
                        {/*                webpage. You will still need to manually input the memo*/}
                        {/*            </li>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            )}
            <Modal close={closeModal} modalIsOpen={transactionStatus}>
                <StatusModal
                    swapData={null}
                    transactionFailedMsg={transactionFailedMsg}
                    resCode={resCode}
                    transactionFailedAddress={transactionFailedAddress}
                    reqId={request_id}
                    closeModal={closeModal}
                    transaction={true}
                />
            </Modal>
        </>
    );
};

export default Transaction;
