import { createSlice } from "@reduxjs/toolkit";

import { IBrigeSlice } from "../interface";

const initialState: IBrigeSlice = {
  publicAdd: "",
  dark: localStorage.getItem("pth$mode")
    ? localStorage.getItem("pth$mode") === "dark"
      ? true
      : false
    : false,
  network: localStorage.getItem("pth$network")
    ? localStorage.getItem("pth$network")
    : "BSC",
  networks: {},
  limit: 0,
  balance: 0,
  dec: 0,
  blurBackground: false,
  transToLang: "en",
  loader: true,
  transactions: [],
  emailVerified:false,
  emailData:{
    email:"",
    input_amount:0
  }
};

export const bridgeSlice = createSlice({
  name: "collectionDetail",
  initialState,
  reducers: {
    setAdd: (state: IBrigeSlice, { payload }) => {
      state.publicAdd = payload || "";
    },
    setDark: (state: IBrigeSlice, { payload }) => {
      state.dark = payload ?? false;
    },
    setNetwork: (state: IBrigeSlice, { payload }) => {
      state.network = payload || {};
    },
    setNetworks: (state: IBrigeSlice, { payload }) => {
      state.networks = payload || {};
    },
    setBalance: (state: IBrigeSlice, { payload }) => {
      state.balance = payload || 0;
    },
    setLimit: (state: IBrigeSlice, { payload }) => {
      state.limit = payload || 0;
    },
    setDecimal: (state: IBrigeSlice, { payload }) => {
      state.dec = payload || 0;
    },
    blurBackground: (state: IBrigeSlice, { payload }) => {
      state.blurBackground = payload ?? false;
    },
    setLanguage: (state: IBrigeSlice, { payload }) => {
      state.transToLang = payload || "en";
    },
    setLoad: (state: IBrigeSlice, { payload = false }) => {
      state.loader = payload;
    },
    setTransactions: (state: IBrigeSlice, { payload = [] }) => {
      state.transactions = payload;
    },
    setEmailVerified: (state: IBrigeSlice, { payload = false }) => {
      state.emailVerified = payload;
    },
    setEmailData: (state: IBrigeSlice, { payload = false }) => {
      state.emailData = payload;
    }
  },
});

export const {
  setAdd,
  setDark,
  setNetwork,
  setBalance,
  setLimit,
  setDecimal,
  blurBackground,
  setLanguage,
  setNetworks,
  setLoad,
  setTransactions,
  setEmailVerified,
  setEmailData
} = bridgeSlice.actions;

export default bridgeSlice.reducer;
